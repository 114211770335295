/* FinancingOptions.module.css */
.financingOptionsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 25px;
  background-color: #eee;
  border-radius: 24px;
  margin-top: 6%;
}

@media (max-width: 991px) {
  .financingOptionsSection {
    padding: 35px 15px;
  }
}

.sectionTitle {
  color: #482b7e;
  font: 700 32px/1 Segoe UI, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
}
.optionsContainer {
  display: flex;
  width: 100%;
  max-width: 1105px;
  margin-top: 32px;
  gap: 20px;
  /* flex-wrap: wrap; Ensure the items wrap to the next line if there's not enough space */
}
@media (max-width: 991px) {
  .optionsContainer {
    flex-direction: column;
    gap: 20;
  }
}
.imagesContainer {
  display: flex;
  margin-top: 10px;
  gap: 8px;
  padding: 8px 0 8px 16px;
}
@media (max-width: 991px) {
  .imagesContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.img {
  aspect-ratio: 1.61;
  object-fit: cover;
  width: 100%;
}
.img2 {
  aspect-ratio: 0.35;
  object-fit: cover;
  width: 72px;
}

.financingOption {
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 27px;
  flex: 1 1 30%; /* Ensure the items take up equal width with margin considerations */
}

@media (max-width: 991px) {
  .financingOption {
    padding: 35px 27px;
  }
}

.optionTitle {
  color: #482b7e;
  letter-spacing: -0.06px;
  text-align: center;
  font: 700 24px/1 Segoe UI, sans-serif;
}
.statsContainer {
  display: flex;
  margin-top: 24px;
  gap: 20px;
  font-size: 12px;
  color: #000;
  text-align: center;
  letter-spacing: -0.03px;
  line-height: 12px;
  justify-content: space-between;
}
.stats {
  font-family: Segoe UI, sans-serif;
  font-weight: 800;
}
.description {
  color: #5f6368;
  text-align: center;
  letter-spacing: -0.04px;
  margin-top: 24px;
  font: 400 18px/24px Segoe UI, -apple-system, Roboto, Helvetica, sans-serif;
}
.findOutMore {
  justify-content: center;
  border-radius: 6px;
  border: 1px solid rgba(49, 130, 206, 1);
  align-self: center;
  margin-top: 24px;
  color: var(--blue-500, #3182ce);
  padding: 6px 12px;
  font: 600 14px/143% Inter, -apple-system, Roboto, Helvetica, sans-serif;
  text-decoration: none;
}
.lightText {
  font-weight: 300;
}

.carousel {
  width: 80%;
  padding: 30px;
}

@media (max-width: 991px) {
  .carousel {
    width: 100%;
    padding: 10px;
    margin-top: 25px;
  }
}

.carouselCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* width: 250px; */
  height: 350px;
  margin: 0 10px; /* Adjust margin to ensure gap between cards */
}

.carouselCard img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.carouselCard h3 {
  margin-top: 1rem;
  font-size: 1.25rem;
  color: #333;
}

.carouselCard p {
  margin: 0.5rem 0;
  color: #666;
}

.slick-slide {
  padding: 0 -10px;
}

.slick-list {
  margin: 0 10px;
}
