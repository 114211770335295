body {
	overflow-y: scroll;
	overflow-x: hidden;
}

:root,
html,
body {
	background-color: #f5f5f5;
	box-sizing: border-box;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
body,
i,
img,
small,
label,
li,
ul,
p,
nav,
span {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

body {
	font-family: Arial, sans-serif;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
