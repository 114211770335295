.modalContent {
  max-width: 600px;
  width: 90%;
  margin: 20px;
}

.formControl {
  margin-bottom: 20px;
}

.formControl:last-child {
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .modalContent {
    width: 95%;
    margin: 10px;
  }
} 