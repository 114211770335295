/* Register.module.css */
.registerContainer {
  border: 2px solid #e2e8f0; /* Light gray border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  max-width: 420px; /* Maximum width */
  width: 100%; /* Full width */
}

.registerHeading {
  color: #2c5282;
  color: #482b7eff;
  font-size: 32px; /* Font size */
}

.subheader {
  padding-bottom: 1rem;
}

.registerForm {
  width: 100%; /* Full width */
}

.registerInput {
  margin-top: 1rem !important;
  height: 48px !important; /* Button height */
  width: 98%; /* Full width */
  font-size: large !important;
  color: black !important;
}

.registerInput::placeholder {
  color: black;
  opacity: 0.6; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: black;
  opacity: 0.6; /* Firefox */
}

.agreement {
  font-size: 12px;
  text-align: center;
  color: rgb(68, 67, 67);
  padding: 1rem;
}

.agreement a {
  color: blue;
  color: #482b7e;
  text-decoration: underline;
}

.registerButton {
  margin-top: 1rem !important; /* Top margin */
  background-color: #2c5282 !important; /* Green background */
  background-color: #482b7e !important; /* Green background */

  color: white !important; /* White text */
  border: none !important; /* No border */
  height: 48px !important; /* Button height */
  font-weight: bold !important; /* Bold font */
  letter-spacing: 0.05em !important; /* Letter spacing */
  transition: background-color 0.3s ease; /* Transition for hover effect */
  font-size: large !important;
}

.registerButton:hover {
  background-color: #1c92a0 !important; /* Darker green on hover */
  background-color: #2f175c !important; /* Darker green on hover */

}

.alternateButton {
  background-color: transparent !important; /* Transparent background */
  color: #333b49 !important; /* Gray text */
  border: 2px solid #cbd5e0 !important; /* Light gray border */
  height: 48px !important; /* Button height */
  margin-top: 1rem !important; /* Top margin */
  font-size: large !important;
}

.alternateButton:hover {
  background-color: #c0c3c9 !important;
}

.smallAlternateButton {
  background-color: transparent !important; /* Transparent background */
  color: #4a5568 !important; /* Gray text */
  border: 2px solid #cbd5e0 !important; /* Light gray border */
  height: 42px !important; /* Button height */
  margin-top: 1rem !important; /* Top margin */
  font-weight: 300 !important; /* Normal font */
}

.smallAlternateButton:hover {
  background-color: #c0c3c9 !important;
}

.alternateButton span {
  color: #2c5282;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5rem;
}

.alternateButton span:hover {
  text-decoration: underline;
}

.forgotPasswordContainer a {
  color: #2c5282;
  color: #3b2462;
  /* font-weight: bold; */
  text-decoration: none;
}

.forgotPasswordContainer {
  margin-top: 1rem;
}

.forgotPasswordContainer:hover {
  text-decoration: underline;
}

.joinNowContainer {
  margin-top: 2rem;
  flex-direction: row;
}

.joinNowContainer a {
  color: #2c5282;
  color: #3b2462;
  font-weight: bold;
  text-decoration: none;
}

.joinNowContainer a:hover {
  text-decoration: underline;
}

.continue_with_logo {
  padding: 1rem;
}

.or_separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #718096;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.or_separator::before,
.or_separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #718096;
  margin: 0 10px;
}