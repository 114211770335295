.successStories {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 60px;
    margin-bottom: 5%;
  }

  @media (max-width: 1500px) {
    .successStories {
      margin-top: 5%;
    }
  }
  
  @media (max-width: 991px) {
    .successStories {
      padding: 0 20px;
    }
  }

  .storiesTitle {
    font: 400 32px 'Segoe UI', sans-serif;
    color: #5f6368;
    letter-spacing: -0.25px;
    text-align: center;
  }

  .imageCollection {
    display: flex;
    justify-content: center;
    gap: 100px;
    margin-top: 32px;
    margin-left: -25px;
  }

  @media (max-width: 991px) {
    .imageCollection {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin-left: 0px;
    }
  }

  .largeImage,
  .mediumImage,
  .smallImage {
    display: flex;
    flex-direction: column;
    line-height: normal;
  }

  .largeImage {
    width: 40%;
    margin-top: 5%;
  }
  
  .mediumImage {
    width: 40%;
  }
  
  .smallImage {
    width: 21%;
  }

  @media (max-width: 991px) {
    .largeImage,
    .mediumImage,
    .smallImage {
      width: 100%;
      margin-left: 0;
    }
  }

  .gridImage {
    object-fit: auto;
    object-position: center;
    max-width: 100%;
  }

  .largeImage .gridImage {
    width: 200px;
    aspect-ratio: 4.76;
  }

  @media (max-width: 991px) {
    .largeImage .gridImage {
      margin-top: 40px;
    }
  }

  .mediumImage .gridImage {
    width: 200px;
    aspect-ratio: 1.56;
  }

  @media (max-width: 991px) {
    .mediumImage .gridImage {
      margin-top: 40px;
    }
  }

  .smallImage .gridImage {
    width: 127px;
    aspect-ratio: 1;
  }

  @media (max-width: 991px) {
    .smallImage .gridImage {
      margin-left: 17%;
      margin-top: 15%;
    }
  }