.chatContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.chatHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.chatHeaderLeft {
    display: flex;
    align-items: center;
    gap: 12px;
}

.headerInfo {
    display: flex;
    flex-direction: column;
}

.headerTitle {
    font-weight: 600;
    font-size: 16px;
    color: #1A1A1A;
}

.headerStatus {
    font-size: 12px;
    color: #22C55E;
}

.closeButton {
    color: #666;
    transition: color 0.2s ease;
}

.closeButton:hover {
    color: #1A1A1A;
}

.chatBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #F9FAFB;
    overflow: hidden;
}

.welcomeMessage {
    text-align: center;
    padding: 32px 20px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.welcomeIcon {
    width: 64px;
    height: 64px;
    margin: 0 auto 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #6B46C1, #805AD5);
    border-radius: 50%;
    color: white;
}

.welcomeMessage h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1A1A1A;
    margin-bottom: 8px;
}

.welcomeMessage p {
    font-size: 14px;
    color: #666;
    max-width: 400px;
    margin: 0 auto;
    line-height: 1.5;
}

.messagesContainer {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.message {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 85%;
}

.message.user {
    align-self: flex-end;
}

.message.bot {
    align-self: flex-start;
}

.messageContent {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.messageBubble {
    padding: 12px 16px;
    border-radius: 16px;
    background: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    position: relative;
}

.message.user .messageBubble {
    background: #6B46C1;
    color: white;
    border-top-right-radius: 4px;
}

.message.bot .messageBubble {
    /* background: rgb(247, 246, 246); */
    border-top-left-radius: 4px;
    
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    color: #1a202c;
}

.messageText {
    font-size: 14px;
    line-height: 1.5;
}

.citationsContainer {
    margin-top: 12px;
    padding: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.citationsLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    color: #1A1A1A;
    margin-bottom: 12px;
}

.citationIcon {
    color: #22C55E;
}

.miniCardsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 12px;
}

.typing {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 16px;
}

.typing span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #6B46C1;
    opacity: 0.4;
    animation: typing 1s infinite ease-in-out;
}

.typing span:nth-child(1) { animation-delay: 0.2s; }
.typing span:nth-child(2) { animation-delay: 0.3s; }
.typing span:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-4px); opacity: 0.8; }
}

.inputContainer {
    padding: 16px 20px;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    gap: 8px;
    width: 100%;
}

.chatInput {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    font-size: 14px;
    transition: all 0.2s ease;
    background: #F9FAFB;
}

.chatInput:focus {
    outline: none;
    border-color: #6B46C1;
    background: white;
    box-shadow: 0 0 0 3px rgba(107, 70, 193, 0.1);
}

.chatInput::placeholder {
    color: #94a3b8;
}

.sendButton {
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    border-radius: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: all 0.2s ease !important;
}

.sendButton:hover {
    transform: translateY(-1px);
}

.sendButton:active {
    transform: translateY(1px);
}

.citationReference {
    display: inline-flex;
    align-items: center;
    padding: 2px 6px;
    margin: 0 2px;
    background: rgba(107, 70, 193, 0.1);
    border-radius: 4px;
    color: #6B46C1;
    font-size: 12px;
    cursor: pointer;
    transition: background 0.2s ease;
}

.citationReference:hover {
    background: rgba(107, 70, 193, 0.2);
}

.highlighted {
    box-shadow: 0 0 0 2px #6B46C1;
}

/* Mini Finance Card Styles */
.miniFinanceCard {
    background: white;
    border-radius: 12px;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    cursor: pointer;
}

.miniFinanceCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.miniCardHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.miniCardTitle {
    font-weight: 600;
    font-size: 14px;
    color: #1A1A1A;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.miniCardBody {
    font-size: 13px;
    color: #666;
}

.miniCardDetails {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.detailItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detailItem span {
    color: #1A1A1A;
    font-weight: 500;
}

.botName {
    font-size: 0.75rem;
    color: #64748b;
    margin-right: 0.5rem;
    font-weight: 500;
}

.messageHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
}

.botName {
    font-size: 0.75rem;
    color: #64748b;
    font-weight: 500;
}

.initializingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 1rem;
    color: #64748b;
    font-size: 0.875rem;
}

/* Markdown Content Container */
.markdownContent {
  font-size: 1rem;
  line-height: 1.7;
  color: #2d3748;
}

.markdownContent p {
  margin-bottom: 1rem;
}

.markdownContent strong,
.markdownContent b {
  color: #1a202c;
  font-weight: 600;
}

.markdownContent h1,
.markdownContent h2,
.markdownContent h3,
.markdownContent h4 {
  color: #1a202c;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.markdownContent h1 { font-size: 1.875rem; }
.markdownContent h2 { font-size: 1.5rem; }
.markdownContent h3 { font-size: 1.25rem; }
.markdownContent h4 { font-size: 1.125rem; }

.markdownContent ul,
.markdownContent ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.markdownContent ul {
  list-style-type: disc;
}

.markdownContent ol {
  list-style-type: decimal;
}

.markdownContent li {
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.markdownContent li > ul,
.markdownContent li > ol {
  margin: 0.5rem 0;
}

.markdownContent code {
  background-color: #f7fafc;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.875em;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
}

.markdownContent pre {
  background-color: #f7fafc;
  padding: 1rem;
  border-radius: 6px;
  overflow-x: auto;
  margin: 1rem 0;
}

.markdownContent blockquote {
  border-left: 4px solid #3182ce;
  padding-left: 1rem;
  margin: 1rem 0;
  color: #4a5568;
  font-style: italic;
}