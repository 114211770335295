.container {
    padding: 20px;
    background-color: #eeeeee;
    border-radius: 24px;
    margin: 10px;
    text-align: center;
  }
  
  .subtitle {
    /* font-family: 'Segoe UI'; */
    font-weight: 700;
    font-size: 32px;
    color: #482b7e;
    margin-bottom: 20px;
    padding-top: 2%;
  }
  
  .team {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .member {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border-radius: 24px;
    margin: 10px;
    width: 220px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .textWrapper {
    padding: 16px;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .name {
    /* font-family: 'Segoe UI'; */
    font-weight: 700;
    padding: 5%;
    font-size: 24px;
    color: #000000;
    margin: 10px 0 5px 0;
  }
  
  .title {
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    margin: 0 0 10px 0;
  }
  
  .linkedIn {
    margin-top: 10px;
  }
  
  .linkedIn img {
    display: initial;
    width: 24px;
    height: 24px;
  }
  