.cardContainer {
  max-width: 680px;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  background-color: #ffffff;
  /* margin-bottom: 24px; */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cardContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.header {
  background-color: #ffffff;
  padding: 24px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.headerContent {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.headerContent > div {
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 1.4;
}

.body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.badge {
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 16px;
  color: #1a1a1a;
  font-size: 14px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.badge:hover {
  background-color: rgba(49, 130, 206, 0.08);
  transform: scale(1.01);
  border-color: rgba(49, 130, 206, 0.3);
}

.visibleBadge {
  display: flex;
  align-items: center;
  gap: 12px;
}

.badgeContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.badgeText {
  font-weight: 600;
  white-space: nowrap;
}

.scoreContainer {
  display: flex;
  gap: 12px;
  font-size: 14px;
  color: #4a5568;
}

.score {
  background-color: #edf2f7;
  padding: 4px 12px;
  border-radius: 8px;
  white-space: nowrap;
  font-weight: 500;
}

.status {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.statusChip {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 24px;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  background-color: #f8fafc;
  padding: 16px;
  border-radius: 12px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  color: #4a5568;
}

.detailItemTitle {
  font-weight: 600;
  color: #2d3748;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.description {
  font-size: 15px;
  color: #2d3748;
  line-height: 1.6;
  padding: 0 8px;
}

.keywords {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
}

.keywords span {
  color: #4a5568;
  font-weight: 500;
  font-size: 14px;
}

.keywordChips {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.chip {
  background-color: #edf2f7;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 13px;
  color: #2d3748;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.chip:hover {
  background-color: #e2e8f0;
}

.footer {
  border-top: 1px solid #f0f0f0;
  padding: 16px 24px;
}

.footerActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.smallActions {
  display: flex;
  gap: 12px;
}

.actionButton {
  height: 36px;
  padding: 0 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.actionButton svg {
  font-size: 16px;
}

.likeButton.liked {
  background-color: #ebf4ff;
  color: #3182ce;
}

@media (max-width: 768px) {
  .cardContainer {
    margin: 0;
  }

  .header, .body {
    padding: 16px;
  }

  .details {
    grid-template-columns: 1fr;
  }

  .footerActions {
    flex-direction: row;
    gap: 8px;
  }

  .smallActions {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }

  .actionButton {
    height: 32px;
    min-width: 32px;
    padding: 0 8px;
    font-size: 13px;
  }
}

.editableTextarea {
  width: 100%;
  min-height: 32px;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: inherit;
  color: inherit;
  background-color: #ffffff;
  resize: vertical;
  transition: border-color 0.2s ease;
}

.editableTextarea:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 0 0 2px rgba(49, 130, 206, 0.1);
}

.editButton {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
}

.editableWrapper {
  position: relative;
  width: 100%;
}

.editControls {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 4px;
}

.editButton {
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.editButton:hover {
  color: #333;
}

.editButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.editableTextarea {
  width: 100%;
  min-height: 30px;
  padding: 4px 8px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: white;
  resize: vertical;
  position: relative;
  z-index: 2;
}

.editableTextarea:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.valueFields {
  display: flex;
  align-items: center;
  gap: 8px;
}

.valueFields .editableTextarea {
  width: 120px;
  min-height: 30px;
}

.detailItem {
  position: relative;
}

.detailItem .editableTextarea {
  margin-top: 4px;
}

.cardContainer {
  position: relative;
}

/* Add styles for edit mode */
.editingMode .detailItem,
.editingMode .description,
.editingMode .keywords {
  background-color: rgba(49, 130, 206, 0.05);
  border-radius: 4px;
  padding: 4px;
}

.description .editableTextarea {
  min-height: 60px;
}

.keywords .editableTextarea {
  min-height: 40px;
}

.listView {
  width: 100%;
}

.gridView {
  width: 100%;
  margin: 0.5rem;
  display: inline-block;
  vertical-align: top;
  min-width: 300px;
}

.gridView .header {
  padding: 1rem;
}

.gridView .body {
  padding: 1rem;
}

.gridView .description {
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gridView .details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.gridView .detailItem {
  font-size: 0.9rem;
}

.gridView .footerActions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-direction: row;
}

.gridView .smallActions {
  display: flex;
  gap: 8px;
}

.gridView .actionButton {
  height: 32px;
  min-width: 32px;
  width: 32px;
  padding: 0;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.gridView .actionButton svg {
  font-size: 16px;
  margin: 0;
}

.gridView .actionButton.consultantButton {
  width: auto;
  padding: 0 12px;
  margin-left: auto;
}

.gridView .actionButton.consultantButton span {
  display: inline;
}

/* Non-grid view button styles */
.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.actionButton svg {
  font-size: 16px;
}

@media (max-width: 1024px) {
  .gridView {
    min-width: 280px;
  }
}

/* Grid view specific styles */
.cardContainer.gridView {
  max-width: 100%;
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gridView .header {
  padding: 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}

.gridView .headerContent {
  gap: 12px;
}

.gridView .headerContent > div {
  font-size: 16px;
}

.gridView .body {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gridView .description {
  font-size: 14px;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
}

.gridView .details {
  grid-template-columns: 1fr;
  gap: 2px;
  padding: 12px;
}

.gridView .detailItem {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
}

.gridView .detailItemTitle {
  font-size: 12px;
}

.gridView .keywords {
  padding: 0;
}

.gridView .keywordChips {
  gap: 6px;
}

.gridView .chip {
  font-size: 12px;
  padding: 4px 8px;
}

.gridView .footer {
  padding: 12px;
  margin-top: auto;
  border-top: 1px solid #f0f0f0;
}

/* Remove the old responsive adjustments for grid view footer since we want single row */
@media (max-width: 1400px) {
  .gridView .footerActions {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .gridView .footerActions {
    flex-direction: row;
  }
  
  .gridView .actionButton {
    padding: 0 6px;
    font-size: 12px;
  }
}

/* Responsive adjustments for grid view footer */
@media (max-width: 1024px) {
  .cardContainer.gridView {
    max-width: none;
  }
}

@media (max-width: 768px) {
  .cardContainer {
    margin: 0;
  }

  .header, .body {
    padding: 16px;
  }

  .details {
    grid-template-columns: 1fr;
  }

  .footerActions {
    flex-direction: row;
    gap: 8px;
  }

  .smallActions {
    display: flex;
    gap: 8px;
    margin-left: inherit;
  }

  .actionButton {
    height: 32px;
    min-width: 32px;
    padding: 0 8px;
    font-size: 13px;
  }
}

@media (min-width: 1400px) {
  .gridView {
    min-width: 350px;
    max-width: none;
  }
}

.onboardingSection {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.onboardingSection:hover {
  background-color: rgba(49, 130, 206, 0.08);
  transform: scale(1.02);
}

.onboardingLink {
  color: #3182ce;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
  display: block;
  width: 100%;
}

.onboardingLink:hover {
  color: #2c5282;
  text-decoration: underline;
}