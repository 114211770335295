.search-dropdown {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}

.search-dropdown-header {
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: linear-gradient(to bottom, #fff, #f9fafb);
}

.tabs {
  display: flex;
  gap: 12px;
}

.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;
}

.tab:hover {
  background: rgba(0, 0, 0, 0.04);
  color: #1a1a1a;
}

.tab.active {
  background: #6B46C1;
  color: white;
}

.filter-badge {
  background: #E53E3E;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 10px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-dropdown-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1a1a1a;
}

.section-icon {
  color: #6B46C1;
}

.section-header h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.filter-group {
  width: 100%;
}

.select-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
  color: #1a1a1a;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.select-input:hover {
  border-color: #6B46C1;
}

.select-input:focus {
  outline: none;
  border-color: #6B46C1;
  box-shadow: 0 0 0 3px rgba(107, 70, 193, 0.1);
}

.select-input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  opacity: 0.7;
}

.date-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.date-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
  color: #1a1a1a;
  transition: all 0.2s ease;
}

.date-input:hover {
  border-color: #6B46C1;
}

.date-input:focus {
  outline: none;
  border-color: #6B46C1;
  box-shadow: 0 0 0 3px rgba(107, 70, 193, 0.1);
}

.sort-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sort-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sort-section h4 {
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.sort-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sort-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.sort-option:hover {
  background: rgba(107, 70, 193, 0.05);
}

.sort-option input[type="radio"] {
  width: 18px;
  height: 18px;
  margin: 0;
  cursor: pointer;
  accent-color: #6B46C1;
}

.radio-label {
  font-size: 14px;
  color: #1a1a1a;
}

.search-dropdown-footer {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background: #f9fafb;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.reset-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.reset-button:hover {
  background: #FFF5F5;
  border-color: #E53E3E;
  color: #E53E3E;
}

.apply-button {
  padding: 8px 24px;
  border: none;
  border-radius: 8px;
  background: #6B46C1;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.apply-button:hover {
  background: #553C9A;
  transform: translateY(-1px);
}

.apply-button:active {
  transform: translateY(1px);
}

@media (max-width: 768px) {
  .search-dropdown {
    max-height: 90vh;
  }

  .date-inputs {
    grid-template-columns: 1fr;
  }

  .search-dropdown-footer {
    padding: 12px;
  }

  .reset-button,
  .apply-button {
    padding: 10px 16px;
    width: 100%;
  }
}

.no-company-message {
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  color: #6c757d;
  font-size: 0.9rem;
}

.no-company-message .info-icon {
  margin-right: 12px;
  color: #17a2b8;
  flex-shrink: 0;
  margin-top: 3px;
}

.no-company-message .message-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.no-company-message span {
  line-height: 1.4;
}

.no-company-message .onboard-link {
  color: #6B46C1;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease;
}

.no-company-message .onboard-link:hover {
  color: #553C9A;
  text-decoration: underline;
}
