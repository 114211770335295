.container {
  padding: 20px;
  background-color: #eeeeee;
  border-radius: 24px;
  margin: 10px;
}

.content {
  /* font-family: "Segoe UI"; */
  font-weight: 400;
  font-size: 32px;
  color: #482b7e;
  font-size: 22px;
  padding: 2%;
}

.highlight {
    font-weight: 700;
}
