.button:hover {
  background: #4a8fb7 !important;
}

.declineButton:hover {
  background: #d4d4d4 !important;
}

.link:hover {
  color: #005582 !important;
}
