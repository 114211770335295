.heroSection {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.heroContent {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 53%;
  margin-left: 0px;
  margin-top: -5%;
}

.heroImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5%;
  width: 100%;
}

@media (max-width: 991px) {
  .heroContent {
    width: 100%;
  }
}

.heroText {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
  margin-bottom: 2%;
}

@media (max-width: 991px) {
 
}

.heroTitle {
  color: #5f6368;
  font: 300 32px Segoe UI, sans-serif;
  letter-spacing: -0.25px;
}

@media (max-width: 991px) {
  .heroTitle {
    max-width: 100%;
  }
}

.heroSubtitle {
  color: #5f6368;
  margin-top: 16px;
  font: 400 48px Segoe UI, sans-serif;
  letter-spacing: -0.25px;
}

@media (max-width: 991px) {
  .heroSubtitle {
    max-width: 100%;
    font-size: 40px;
  }
}

.highlight {
  color: #482b7e;
  font-weight: 700;
}

.authButtons {
  align-self: stretch;
  display: flex;
  gap: 30px;
  font-size: 22px;
  font-weight: 600;
  line-height: 143%;
  margin: auto 0;
  margin-top: 4%;
}

.signupBtn {
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 6px;
  background-color: #482b7e;
  color: #fff;
  padding: 6px 12px;
  border: none;
  cursor: pointer;
}

.loginBtn {
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid rgba(49, 130, 206, 1);
  color: var(--blue-500, #3182ce);
  padding: 6px 12px;
  background-color: transparent;
  cursor: pointer;
}

.signupBtn:hover {
  background-color: #2f175c;
  color: #fff;
  border: none;
}

.loginBtn:hover {
  background-color: #edf1f4;
  color: #3182ce;
  border: 1px solid #3182ce;
}

p {
  opacity: 1;
}

@media (max-width: 768px) {
  .heroSection {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
  .heroImage {
    margin-bottom: 25%;
  }
}

@media (max-width: 768px) {
  .heroSection {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
  .heroImage {
    margin-top: 10%;
    margin-bottom: 15%;
  }
}