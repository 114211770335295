.pricing {
  padding: 2rem;
  background-color: #eeeeeeff;
  border-radius: 24px;
  margin-top: 3%;
}

.title {
  color: #482b7eff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.pricingCards {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.pricingCard {
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  width: 284px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.pricingCard:hover,
.pricingCard.selected {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.recommended {
  border: 2px solid #17bfe6ff;
}

.recommendedLabel {
  background-color: #ffab00;
  color: #fff;
  padding: 0.25rem 1rem;
  border-radius: 2px;
  position: relative;
  top: -285px;
  left: 25%;
  transform: translateX(-50%);
}

.price {
  font-size: 20px;
  font-weight: 600;
  color: #482b7eff;
  text-align: center;
  margin: 1rem 0;
}

ul {
  padding-left: 1rem;
  list-style: none;
}

li {
  color: #8f8a8aff;
  font-size: 14px;
  margin: 0.5rem 0;
}

.readMore {
  color: #17bfe6ff;
  text-decoration: none;
  font-weight: 600;
  display: block;
  margin: 1rem 0;
}

.startToday {
  background-color: #482b7eff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.startToday:hover {
  background-color: #3b2462;
}
