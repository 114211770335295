.financeOption {
    margin: 16px;
    /* padding: 16px; */
  }
  
  .financeOption h2 {
    color: #573e6f;
    margin-right: auto;
    font-size: large;
    font-weight: bold;
  }
  
  .sortButton,
  .filterButton {
    color: #7e3dbc;
    margin: 4px;
    display: flex;
    padding-left: 2%;
  }

  .header {
    display: flex;
  }
  
  .highlight {
    background-color: #9bb64e;
    border-radius: 16px;
    padding: 24px;
    margin-top: 16px;
  }
  
  .highlight h3 {
    color: #fff;
  }
  
  .highlight button {
    background-color: #fff;
    color: #7e3dbc;
    border: none;
    padding: 8px 16px;
  }
  
  .financeCard {
    border: 1px solid #cac4d0;
    border-radius: 16px;
    margin-top: 24px;
  }
  
  .cardHeader {
    background-color: #7e3dbc;
    color: #fff;
    padding: 24px;
    display: flex;
    align-items: center;
  }
  
  .initial {
    background-color: #49454f;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }
  
  .cardBody {
    padding: 16px;
  }
  
  .infoChip {
    background-color: #eeeeee;
    border-radius: 30px;
    border: 1px solid #79747e;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    color: #239565fa;
  }
  
  .info {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin: 16px 0;
  }
  
  .keywords {
    display: flex;
    gap: 8px;
  }
  
  .keywords a {
    border: 1px solid #79747e;
    border-radius: 8px;
    padding: 4px 12px;
    text-decoration: underline;
    color: #7e3dbc;
  }
  
  .actions {
    display: flex;
    gap: 8px;
  }
  
  .actions button {
    border: 1px solid #573e6f;
    border-radius: 50px;
    padding: 8px 16px;
  }
  
  .actions .primary {
    background-color: #573e6f;
    color: #fff;
  }
  
  .container {
    /* padding: 3px; */
    width: 100%;
    margin: 0 auto;
  }
  
  .fundingList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  
  /* Grid view styles */
  .fundingList.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 100%;
  }
  
  /* Card styles in grid view */
  .gridContainer > div {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  
  /* Style adjustments for grid view cards */
  .gridContainer :global(.cardContainer) {
    height: 100%;
    width: 100%;
    max-width: none;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin: 0 !important;
  }
  
  .gridContainer :global(.cardContainer:hover) {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Responsive adjustments */
  @media (min-width: 1400px) {
    .fundingList.gridContainer {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 991px) {
    .fundingList.gridContainer {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
  
  @media (max-width: 767px) {
    .fundingList.gridContainer {
      grid-template-columns: 1fr;
      /* padding: 0.5rem; */
    }
  }
  
  /* Loading and end message styles
  .loadingCard,
  .endCard {
    grid-column: 1 / -1;
    width: 100% !important;
    margin: 1rem 0;
  } */

  .loadingCard {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 678px; /* Ensure it takes full width of its container */
  }
  
  .endCard {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100% !important;
    grid-column: 1 / -1;

    p {
      padding: 20px;
    }
  }
  
  @media (max-width: 720px) {
    .loadingCard {
      width: 94vw; /* Ensure it takes full width the screen */
    }
  }
  
  .loadingAnimation {
    width: 50px;
    height: 50px;
    border: 3px solid #e0e0e0;
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  