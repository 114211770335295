.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4c00ff57;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, bottom 0.3s;
  z-index: 100;
}

.scrollToTopButton:hover {
  background-color: #4c00ffaf;
  transform: scale(1.1);
}

.scrollToTopButton svg {
  width: 24px;
  height: 24px;
}

.headerVisible {
  bottom: 20px; /* Adjust this value based on your header's height */
  right: 100px;
}

@media (max-width: 768px) {
  .scrollToTopButton {
    width: 40px;
    height: 40px;
    right: 15px;
    bottom: 15px;
  }

  .scrollToTopButton svg {
    width: 20px;
    height: 20px;
  }

  .headerVisible {
    bottom: 70px; /* Adjust this value for mobile if needed */
  }
}
