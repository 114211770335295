/* Base layout */
.pageLayout {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  gap: 1rem;
  min-height: 100vh;
  background-color: #f8fafc;
}

/* Table of Contents - Compact Version */
.tableOfContents {
  min-width: 180px;
  position: sticky;
  top: 2rem;
  align-self: flex-start;
  background-color: white;
  padding: 0.75rem;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  height: fit-content;
  font-size: 0.875rem;
}

.tableOfContents h2 {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  color: #64748b;
  font-weight: 600;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.tableOfContents ul {
  list-style-type: none;
  padding: 0;
}

.tableOfContents li {
  margin-bottom: 0.25rem;
}

.tableOfContents button {
  background: none;
  border: none;
  color: #64748b;
  cursor: pointer;
  font-size: 0.875rem;
  text-align: left;
  padding: 0.375rem 0.5rem;
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s ease;
  line-height: 1.2;
}

.tableOfContents button:hover {
  background-color: #f1f5f9;
  color: #3182ce;
}

/* Main content container */
.detailsContainer {
  flex-grow: 1;
  /* max-width: 100%; */
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 1rem;
}

.detailsContainer h1 {
  font-size: 2rem;
  color: #1a202c;
  margin-bottom: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

/* Overview Section */
.overviewSection {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid #e2e8f0;
}

.overviewSection h2 {
  font-size: 1.5rem;
  color: #1a202c;
  margin-bottom: 1.5rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #3182ce;
}

.overviewGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.overviewItem {
  display: flex;
  flex-direction: center;
  gap: 0.5rem;
}

.overviewLabel {
  font-size: 0.875rem;
  color: #64748b;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.overviewValue {
  font-size: 1rem;
  color: #1a202c;
  font-weight: 500;
}

.statusChip {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-weight: 600;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.statusChip:hover {
  transform: translateY(-1px);
}

/* Content Sections */
.section {
  margin-top: 2rem;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #f8fafc;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #e2e8f0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sectionHeader:hover {
  background-color: #f1f5f9;
}

.sectionHeader h2 {
  font-size: 3 rem;
  color: #1a202c;
  margin: 0;
  font-weight: 600;
}

.sectionContent {
  padding: 1.5rem;
  color: #4a5568;
  line-height: 1.6;
  font-size: 1rem;
}

.sectionContent p {
  margin-bottom: 1rem;
  line-height: 1.7;
}

.sectionContent strong,
.sectionContent b {
  color: #2d3748;
  font-weight: 600;
}

.sectionContent h1,
.sectionContent h2,
.sectionContent h3,
.sectionContent h4 {
  color: #1a202c;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.sectionContent h1 { font-size: 1.875rem; }
.sectionContent h2 { font-size: 1.5rem; }
.sectionContent h3 { 
  font-size: 1.25rem;
  color: #2d3748;
}
.sectionContent h4 { font-size: 1.125rem; }

.sectionContent ul,
.sectionContent ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.sectionContent ul {
  list-style-type: disc;
}

.sectionContent ol {
  list-style-type: decimal;
}

.sectionContent li {
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.sectionContent li > ul,
.sectionContent li > ol {
  margin: 0.5rem 0;
}

/* Nested list styling */
.sectionContent ul ul,
.sectionContent ol ul {
  list-style-type: circle;
}

.sectionContent ul ul ul,
.sectionContent ol ul ul {
  list-style-type: square;
}

/* Special formatting for bullet points */
.sectionContent li::marker {
  color: #3182ce;
}

/* Code blocks and inline code */
.sectionContent code {
  background-color: #f7fafc;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.875em;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
}

.sectionContent pre {
  background-color: #f7fafc;
  padding: 1rem;
  border-radius: 6px;
  overflow-x: auto;
  margin: 1rem 0;
}

.sectionContent pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

/* Blockquotes */
.sectionContent blockquote {
  border-left: 4px solid #3182ce;
  padding-left: 1rem;
  margin: 1rem 0;
  color: #4a5568;
  font-style: italic;
}

/* Tables */
.sectionContent table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.sectionContent th,
.sectionContent td {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  text-align: left;
}

.sectionContent th {
  background-color: #f7fafc;
  font-weight: 600;
  color: #2d3748;
}

.sectionContent tr:nth-child(even) {
  background-color: #f8fafc;
}

/* Horizontal rules */
.sectionContent hr {
  border: 0;
  border-top: 1px solid #e2e8f0;
  margin: 2rem 0;
}

/* Links */
.sectionContent a {
  color: #3182ce;
  text-decoration: none;
  transition: color 0.2s ease;
}

.sectionContent a:hover {
  color: #2c5282;
  text-decoration: underline;
}

/* Description lists */
.sectionContent dl {
  margin: 1rem 0;
}

.sectionContent dt {
  font-weight: 600;
  color: #2d3748;
  margin-top: 1rem;
}

.sectionContent dd {
  margin-left: 1rem;
  margin-top: 0.25rem;
}

/* Evaluation Section */
.evaluationSection {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 2rem;
  border: 1px solid #e2e8f0;
}

.evaluationPoints h3 {
  font-size: 1.25rem;
  color: #1a202c;
  margin: 1.5rem 0 1rem;
  font-weight: 600;
}

.evaluationPoints ul {
  list-style-type: none;
  padding: 0;
}

.evaluationPoints li {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  background-color: #f8fafc;
  border-radius: 8px;
  transition: transform 0.2s ease;
}

.evaluationPoints li:hover {
  transform: translateX(4px);
}

.iconGreen { color: #059669; }
.iconRed { color: #dc2626; }
.iconBlue { color: #2563eb; }

.evaluationNotes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.noteBox {
  background-color: #f8fafc;
  border-radius: 12px;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
}

.noteBox h4 {
  font-size: 1.1rem;
  color: #1a202c;
  margin: 0 0 1rem;
  font-weight: 600;
}

.noteMark {
  font-size: 2rem;
  font-weight: 700;
  color: #3182ce;
  margin: 1rem 0;
}

/* Chat Section */
.chatSection {
  background-color: transparent;
  border-radius: 6px;
  /* padding: 0.5rem; */
  margin: 0.5rem 0;
  border: none;
  font-size: 0.75rem;
  position: relative;
}

.chatSection h2 {
  font-size: 0.875rem;
  color: #94a3b8;
  margin-bottom: 0.5rem;
  font-weight: 400;
  padding-bottom: 0;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #f8fafc;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Edit Button */
.editButton {
  position: fixed !important;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  padding: 0.75rem 1.5rem !important;
  border-radius: 9999px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  transition: all 0.2s ease !important;
}

.editButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.1), 0 3px 6px -1px rgba(0, 0, 0, 0.06) !important;
}

/* Editable Fields */
.editableTextarea {
  width: 100%;
  min-height: 40px;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: white;
  resize: vertical;
  transition: all 0.2s ease;
}

.editableTextarea:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .pageLayout {
    flex-direction: column;
    padding: 1rem;
  }

  .tableOfContents {
    position: static;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .detailsContainer {
    max-width: 100%;
  }

  .overviewGrid {
    grid-template-columns: 1fr;
  }

  .evaluationNotes {
    grid-template-columns: 1fr;
  }
}

/* Animation for section expansion */
.sectionContent {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.sectionContent.expanded {
  max-height: 2000px;
  opacity: 1;
}

/* Loading and Error States */
.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.loading {
  color: #3182ce;
}

.error {
  color: #dc2626;
}

/* Markdown Content Container */
.markdownContent {
  font-size: 1rem;
  line-height: 1.7;
  color: #2d3748;
}

.markdownContent p {
  margin-bottom: 1rem;
}

.markdownContent strong,
.markdownContent b {
  color: #1a202c;
  font-weight: 600;
}

.markdownContent h1,
.markdownContent h2,
.markdownContent h3,
.markdownContent h4 {
  color: #1a202c;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.3;
}

.markdownContent h1 { font-size: 1.875rem; }
.markdownContent h2 { font-size: 1.5rem; }
.markdownContent h3 { font-size: 1.25rem; }
.markdownContent h4 { font-size: 1.125rem; }

.markdownContent ul,
.markdownContent ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.markdownContent ul {
  list-style-type: disc;
}

.markdownContent ol {
  list-style-type: decimal;
}

.markdownContent li {
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.markdownContent li > ul,
.markdownContent li > ol {
  margin: 0.5rem 0;
}

.markdownContent code {
  background-color: #f7fafc;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.875em;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
}

.markdownContent pre {
  background-color: #f7fafc;
  padding: 1rem;
  border-radius: 6px;
  overflow-x: auto;
  margin: 1rem 0;
}

.markdownContent blockquote {
  border-left: 4px solid #3182ce;
  padding-left: 1rem;
  margin: 1rem 0;
  color: #4a5568;
  font-style: italic;
}

.disclaimer {
  margin-top: 3rem;
  padding: 1.5rem;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #6c757d;
  line-height: 1.6;
}

.disclaimer p {
  margin: 0;
  text-align: justify;
}
