.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 57px 60px;
  background-color: #eee;
  border-radius: 24px;
  margin-top: 3%;
}
@media (max-width: 991px) {
  .howItWorks {
    padding: 20px;
  }
}

.title {
  color: #482b7eff;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 991px) {
  .title {
    font-size: 24px;
  }
}

.steps {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
}
@media (max-width: 991px) {
  .steps {
    flex-direction: column;
    align-items: center;
  }
}

.step {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
}
@media (max-width: 991px) {
  .step {
    flex-direction: column;
    text-align: center;
  }
}

.circle {
  width: 64px;
  height: 64px;
  background-color: #482b7eff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 900;
  color: #fff;
  flex-shrink: 0;
}
@media (max-width: 991px) {
  .circle {
    width: 48px;
    height: 48px;
    font-size: 24px;
  }
}

.stepDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .stepDetails {
    align-items: center;
  }
}

.stepTitle {
  font-size: 24px;
  color: #482b7eff;
}
@media (max-width: 991px) {
  .stepTitle {
    font-size: 20px;
  }
}

.stepDescription {
  font-size: 18px;
  color: #5f6368ff;
}
@media (max-width: 991px) {
  .stepDescription {
    font-size: 16px;
  }
}

.line {
  width: 64px;
  height: 2px;
  background-color: #5f6368ff;
  margin: 0 auto;
  margin-top: 45px;
}
@media (max-width: 991px) {
  .line {
    display: none;
  }
}
