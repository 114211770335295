.header {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 12px 0px;
}

.navigation {
  align-self: stretch;
  display: flex;
  gap: 20px;
  margin: auto 0;
}

.navItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  position: relative;
  transition: color 0.3s;
  color: var(--character-title-85, rgba(39, 39, 39, 0.85));
  font: 400 14px/157% Segoe UI, -apple-system, Roboto, Helvetica, sans-serif;
}

.navItem:hover,
.navItem.active {
  color: #1890ff;
}

.navItem::after {
  content: "";
  display: block;
  height: 2px;
  background-color: transparent;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: background-color 0.3s;
}

.navItem:hover::after,
.navItem.active::after {
  background-color: #1890ff;
}

.navIcon {
  width: 22px;
  height: 22px;
  margin-right: 4px;
  margin-bottom: 5px;
}

.authButtons {
  display: flex;
  gap: 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 143%;
}

.signupBtn,
.loginBtn {
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
}

.signupBtn {
  background-color: #482b7e;
  color: #fff;
  border: none;
}

.loginBtn {
  border: 1px solid rgba(49, 130, 206, 1);
  color: var(--blue-500, #3182ce);
  background-color: transparent;
}

.signupBtn:hover {
  background-color: #2f175c;
  color: #fff;
  border: none;
}

.loginBtn:hover {
  background-color: #edf1f4;
  color: #3182ce;
  border: 1px solid #3182ce;
}

/* Burger Icon Styling */
.burgerIcon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  margin-right: 20px;
  margin-top: 5px;
}

/* Drawer Styling */
.openDrawer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  background-color: white;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(0px);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.authButtonsDrawer {
  display: flex;
  gap: 10px;
}

@media (max-width: 991px) {
  .navigation {
    display: none;
  }

  .burgerIcon {
    display: block;
  }

  .header {
    padding: 12px 16px;
  }

  .logo {
    align-self: center;
    width: 52px;
  }

  .openDrawer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: white;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transform: translateX(0px);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding-top: 7%;
  }
}
