.nav {
  max-width: 1128px;
  margin: auto;
  padding: 12px 0 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.burger {
  display: none;
  font-size: 24px;
  border: none;
  background: none;
}

.menu {
  display: flex;
  gap: 20px;
}

.menu.expanded {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.link-page {
  display: flex;
  align-items: center;
  /* text-decoration: none; */
  color: #1c92a0;
  /* transition: color 0.1s ease; */
}

.link-page:hover {
  color: #0a3a3f;
}

.icon {
  margin-right: 8px;
  font-size: 32px;
}

.link-text {
  text-align: left;
  flex: 1; /* Allows text to take up the remaining space */
  white-space: normal; /* Allows text wrapping */
  size: small;
  font-size: 16px;
  padding: 5px;
}

@media (max-width: 768px) {
  .burger {
    display: block;
  }

  .menu {
    display: none;
  }

  .nav > a {
    padding: 0 5px;
  }
}
/* -------------------------------- */
.nav > a {
  width: 130px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .nav > a {
    padding: 0 5px;
  }
}

.flex {
  display: flex;
  gap: 2vw;
  justify-self: center;
  border-right: solid 2px #656568;
  padding-right: 10px;
  margin-left: 400px;
  height: 80px;
}

.logo-finaccess {
  max-width: 50%;
}

.link-page {
  display: flex;
  /* flex-direction: column; */
  text-decoration: none;
  color: #1c92a0;
  align-items: center;
  margin-top: 10px;
  /* font-size: large; */
  font-weight: bold;
  max-width: min-content;
  background-color: #d9d9d9;
  border-radius: 10px;
}

.first-word {
  font-weight: 600;
  margin-bottom: 5px;
}

.log-buttons {
  font-size: large;
}

.sign-in {
  color: #14919f;
  font-weight: 600;
  transition-duration: 167ms;
  line-height: 40px;
  padding: 10px 25px;
  margin-right: 10px;
  text-align: center;
  background-color: transparent;
  white-space: nowrap;
  text-decoration: none;
}

.sign-in:hover {
  background-color: rgba(112, 181, 249, 0.15);
  box-shadow: inset 0 0 0 2px #0a66c2;
}

.join {
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 40px;
  background-color: #d9d9d9;
  color: #14919f;
  margin-right: 8px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
}

.join:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 1);
}
