@media (max-width: 768px) {
    .section {
        min-height: 0;
    }

    .hero h1 {
        text-align: center;
        width: 100%;
        font-size: 20px;
        line-height: 2;
    }

    .hero img {
        top: 230px;
        position: initial;
        width: auto;
        height: auto;
    }

    .form {
        margin: 20px auto 0;
    }
}

.section {
    display: flex;
    flex-wrap: wrap;
    align-content: start;
    min-height: 700px;
    padding: 60px 0;
    position: relative;
    width: 100%;
    max-width: 1128px;
    margin: auto;
}

.hero {
    width: 100%;
}

.hero h1 {
    padding-bottom: 0;
    width: 55%;
    font-size: 56px;
    color: #2977c9;
    font-weight: 200;
    line-height: 70px;
}

.hero img {
    width: 700px;
    height: 670px;
    position: absolute;
    bottom: -2px;
    right: -150px;
}

.form {
    margin-top: 100px;
    width: 408px;
}

.google {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 56px;
    width: 100%;
    border-radius: 30px;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 60%);
    border: none;
    vertical-align: middle;
    transition-duration: 167ms;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    z-index: 0;
}

.google:hover {
    background-color: rgba(207, 207, 207, 0.25);
    color: rgba(0, 0, 0, 0.75);
    box-shadow: inset 0 0 0 2px rgb(0 0 0 / 60%);
}

.google img {
    margin-right: 25px;
}

/* Consolidated redundant and similar selectors */
.section-hero {
    display: flex;
    flex-direction: column;
}

.title-container {
    align-self: flex-start;

}

.title-top, .email-label, .pass-label, .or-paragraph, .join-now-button {
    color: #656568;
}

.title-top {
    font-size: 54px;
    margin-bottom: -30px;
    text-decoration: none;
}

.finaccess-logo {
    width: 35vh;
}

.container-mid {
    display: flex;
    width: 1128px;
}

.flex-container {
    font-size: xx-large;
}

.flex-inside {
    display: flex;
    flex-direction: column;
}

.email-container, .pass-container {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.email-label, .pass-label {
    font-size: 16px;
    margin-left: 30px;
}

.email-input, .pass-input {
    border: none;
    border-radius: 50px;
    background-color: #eaf5f6;
    height: 4vh;
}

.pass-container {
    margin-top: 20px;
}

.forgot-password-button {
    background-color: white;
    color: #1491b4;
    font-size: 18px;
}

.login-button {
    width: 100%;
    height: 4vh;
    font-size: 18px;
    background-color: #14919f;
}

.login-button:hover {
    background-color: #126d7c;
}

.or-paragraph {
    font-size: 24px;
    margin-top: 20px;
}

.or-paragraph span {
    color: #656568;
}

.join-now-button {
    margin-top: 20px;
    width: 100%;
    border-radius: 50px;
    font-size: 20px;
    border-color: #e3e3e4;
    border-style: solid;
    border-width: 5px;
    height: 7vh;
}

.join-now-button:hover {
    background-color: rgb(189, 215, 230);
}

.join-now-button span {
    color: #14919f;
    font-weight: 600;
}

.main-image {
    width: 700px;
    object-fit: scale-down;
    padding-left: 5em;
    margin-top: -5em;
}
