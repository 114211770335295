.container {
  padding: 20px;
  padding-top: 10%;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
  }
}

.section {
  width: 30%;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .section {
    width: 100%;
  }
}

.title {
  /* font-family: 'Segoe UI'; */
  font-weight: 700;
  font-size: 32px;
  color: #482b7e;
  margin-bottom: 10px;
}

.titleItalic {
  /* font-family: 'Segoe UI'; */
  font-weight: 700;
  font-style: italic;
  font-size: 20px;
  color: #482b7e;
  margin-bottom: 10px;
}

.description {
  /* font-family: 'Segoe UI'; */
  font-weight: 400;
  font-size: 32px;
  color: #5f6368;
  margin-bottom: 10px;
}

.descriptionSmall {
  /* font-family: 'Segoe UI'; */
  font-weight: 400;
  font-size: 18px;
  color: #35363a;
}

.moreInfoButton {
  display: inline-block;
  padding: 10px 24px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #482b7e;
  border: 1px solid #482b7e;
  border-radius: 6px;
  text-decoration: none;
}

.moreInfoButton:hover {
  background-color: #482b7e;
  color: #ffffff;
}
