.eventsDrawer {
  /* padding: 24px 12px; */
  margin-top: 16px;
  background-color: white;
  /* padding: 24px 12px; */
  border-radius: 10px;
  /* Add your container styles here */
}

.section {
  /* Container for the section */
}

.sectionHeader {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-top: 16px;
  padding-left: 16px;
}

.navItemEvents {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.navItemEvents:last-child {
  border-bottom: none;
}

.eventImage img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 10px;
}

.eventDetails {
  display: flex;
  flex-direction: column;
}

.eventTitle {
  font-size: 14px;
  font-weight: bold;
}

.eventSubtitle {
  font-size: 12px;
  color: gray;
}

.selectedNavItem {
  background-color: #f0f0f0;
}

.discoverAll {
  text-align: center;
  margin-top: 10px;
}

.discoverAll a {
  color: #6c63ff;
  text-decoration: none;
  font-weight: bold;
}
