/* Feed2.module.css */

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.app {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  width: 100%;
  max-width: 1300px;
  gap: 1rem;
}

.app.gridView {
  grid-template-columns: 300px minmax(0, 1fr);
  max-width: 1800px;
  padding: 10px;
}

.centerContainer {
  padding: 0 20px;
  overflow: auto; /* Ensure the center content scrolls if it overflows */
  width: 100%;
  min-width: 0; /* Allow container to shrink below min-content width */
}

.rightContainer {
  flex: 1;
  max-width: 300px;
  height: calc(100vh - 60px); /* Adjust based on your header height */
  overflow-y: auto;
}

.rightContainer.gridView {
  display: none;
}

@media (max-width: 1200px) {
  .app {
    grid-template-columns: 300px 1fr; /* Remove the right column */
    width: auto;
  }

  .rightContainer {
    display: none; /* Hide the right container */
  }
}

@media (max-width: 991px) {
  .app {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
  }

  .centerContainer {
    padding: 20px;
    margin-left: 0;
    width: 100%;
  }

  .rightContainer {
    /* width: 100%; */
    /* margin-right: 0; */
    /* margin-top: 20px; */
  }

  .viewToggle {
    display: none;
  }
}

/* Force grid view on mobile */
@media (max-width: 991px) {
  .app:not(.gridView) {
    grid-template-columns: 300px minmax(0, 1fr);
    max-width: 1800px;
    padding: 10px;
  }

  .rightContainer:not(.gridView) {
    display: none;
  }
}

/* Add these new styles to your existing CSS file */

.loadingCard {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 678px; /* Ensure it takes full width of its container */
}

.endCard {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 678px; /* Ensure it takes full width of its container */
}

@media (max-width: 720px) {
  .loadingCard {
    width: 94vw; /* Ensure it takes full width the screen */
  }
}

.loadingAnimation {
  width: 50px;
  height: 50px;
  border: 3px solid #e0e0e0;
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.viewToggle {
  display: flex;
  justify-content: flex-end;
  padding: 0rem 1rem;
  background-color: transparent;
  border: none;
  /* position: absolute; */
  right: 1rem;
  top: 0.5rem;
  z-index: 1;
  gap: 4px;
}

.viewToggle button {
  width: 28px !important;
  height: 28px !important;
  min-width: 28px !important;
  padding: 0 !important;
  background: transparent !important;
  color: #718096 !important;
  border: none !important;
  box-shadow: none !important;
}

.viewToggle button:hover {
  color: #4A5568 !important;
  background: rgba(0, 0, 0, 0.05) !important;
}

.viewToggle button[aria-pressed="true"] {
  color: #3182CE !important;
}

@media (max-width: 991px) {
  .viewToggle {
    display: none;
  }
}
