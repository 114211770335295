.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 24px 64px 0;
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
}

.separator {
  border-top: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  margin-top: 31px;
  height: 1px;
}

@media (max-width: 991px) {
  .separator {
    max-width: 100%;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  margin: 32px 36px 0;
}

@media (max-width: 991px) {
  .mainContent {
    max-width: 100%;
    margin-right: 10px;
  }
}

.title {
  text-align: center;
  font-weight: 100;
  font-size: 32px;
  color: #5f6368;
}

.subtitle {
  text-align: center;
  font-weight: 300;
  font-size: 48px;
  color: #6d6d70;
  margin: 20px 0;
}
