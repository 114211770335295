.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 24px 64px 0;
  width: 100%;
  box-sizing: border-box; /* Ensure padding does not cause overflow */
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 0px;
  }
}

.separator {
  border-top: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  height: 1px;
  width: 100%;
}

@media (max-width: 991px) {
  .separator {
    display: none;
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box; /* Ensure padding does not cause overflow */
}

@media (max-width: 991px) {
  .mainContent {
    max-width: 100%;
    padding: 16px;
  }
}
